@import "bootstrap/bootstrap.less";
@import "koel/koel.less";
@import "koel/koel-theme.less";

.uniform__potty {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.coupon-code input {
    width: 25%;
    display: inline-block;
}

.breadcrumb {
    > .active {
        a {
            color: @blue4 !important;
            font-weight: bold;
        }
    }
}

div.section {
    margin-top: 10px;
    margin-bottom: 20px;
}

.buttons {
    margin-top: 1em;
}

.form-control {
    display: inline-block;
    width: 95%;
}

span.required {
    display: inline-block;
    font-weight: 900;
    font-size: large;
}

.gender {
    float: left;
    margin-right: 3em;
}

.shop-item {
    .shop-image IMG {
        width: 100%;
    }

    .shop-caption {
        .shop-details {
            .shop-label {
                min-height: 45px;
                 span{
                font-size: 16px !important;
                display: inline-block;
                text-transform: none;
            }
            }
        }
    }
}

.footer {
    ul {
        list-style: circle;

        li {
            text-align: left;
        }
    }
}

.home-page-product-grid {
    margin-top: 30px;
}

.payment-method {
    UL.method-list {
        list-style: none;
        float: left;
    }
}

#language-dropdown {
    display: inline;
    float: left;
}

.page-heading {
    margin-bottom: 30px;
}

.gender {
    width: 100%;
}

.sidebar {
    .customer-blocks {
        UL LI {
            a.active {
                color: @blue4;
                font-weight: bold;
            }
        }
    }
}

.address-list {
    .address-item {
        UL {
            list-style: none;

            LI {
                LABEL {
                    font-weight: bold;
                }
            }
        }
    }
}

.order-review-data, .order-details-area {
    UL {
        list-style: none;

        LI {
            &.title {
                font-weight: bold;
            }

            & + LI.title {
                margin-top: 10px;
            }
        }
    }
}

.total-info {
    padding: 10px 0;
}

.order-overview {
    margin-bottom: 10px;
}

#eu-cookie-bar-notification {
    .content {
        height: 45px;

        * {
            display: inline-block;
            float: left;
            margin-right: 10px;
        }

        div, a {
            margin-top: 15px;
            font-weight: bold;
        }

        button {
            margin-top: 5px;
        }
    }
}

#ph-topic {
    .img {
        margin: 10px 0;
    }

    .img-right {
        float: right;
        padding-left: 20px;
    }

    .img-left {
        float: left;
        padding-right: 20px;
    }
}

@media (max-width: 786px) {
    main section {
        padding-top: 0px !important;
    }

    .navbar {
        margin-bottom: 0px !important;
    }
}

@media (max-width: 480px) {
}

@import "jquery.selz.less";